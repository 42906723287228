var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"productRules"},[_c('div',{staticClass:"w-100 px-1 py-2 mt-1 create-form-body row m-0"},[_c('div',{staticClass:"col-lg-9"},[_c('b-form-group',{attrs:{"label":_vm.$t('Product title'),"label-for":"productTitle"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Product title'),"rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"productTitle","placeholder":_vm.$t('Place some text there...'),"state":errors.length > 0 ? false : null},model:{value:(_vm.product_title),callback:function ($$v) {_vm.product_title=$$v},expression:"product_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Description'),"label-for":"tinymce-editor"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TinyMCE',{attrs:{"id":"tinymce-editor","placeholder":_vm.$t('Place some text there...')},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":_vm.statuses},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":_vm.$t('Manufacturer'),"label-for":"manufacturer"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Manufacturer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"manufacturer","placeholder":_vm.$t('Place some text there...'),"state":errors.length > 0 ? false : null},model:{value:(_vm.manufacturer),callback:function ($$v) {_vm.manufacturer=$$v},expression:"manufacturer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":_vm.$t('Price'),"label-for":"price"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"price","type":"number","placeholder":_vm.$t('Price'),"state":errors.length > 0 ? false : null},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex flex-wrap mb-1"},[_c('div',{staticClass:"\n              file-input\n              mt-1\n              mr-1\n              d-flex\n              flex-column\n              align-items-center\n              justify-content-center\n            "},[_c('img',{attrs:{"src":require("@/assets/images/pages/marketplace/file-drop-image.svg"),"alt":""}}),_c('h6',{staticClass:"font-weight-bolder text-center px-1 pt-1"},[_vm._v(" "+_vm._s(_vm.$t("Drop an image here or"))+" "),_c('span',{staticClass:"text-primary cursor-pointer"},[_vm._v(_vm._s(_vm.$t("click")))]),_vm._v(" "+_vm._s(_vm.$t("to upload"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Images'),"rules":_vm.$route.params.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"drop-input",attrs:{"id":"files","multiple":"","state":errors.length > 0 ? false : null},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._l((_vm.oldImages),function(link,index){return _c('div',{key:index,staticClass:"image-preview mr-1 mt-1"},[_c('img',{attrs:{"src":link.src ? link.src : link,"alt":""}}),_c('img',{staticClass:"close-btn",attrs:{"src":require("@/assets/images/pages/marketplace/x-circle.svg"),"alt":""},on:{"click":function($event){return _vm.deleteOldImage(index)}}})])}),_vm._l((_vm.newImages),function(link,index){return _c('div',{key:("new-" + index),staticClass:"image-preview mr-1 mt-1"},[_c('img',{attrs:{"src":link.src ? link.src : link,"alt":""}}),_c('img',{staticClass:"close-btn",attrs:{"src":require("@/assets/images/pages/marketplace/x-circle.svg"),"alt":""},on:{"click":function($event){return _vm.deleteImage(index)}}})])})],2),(!this.$route.params.id && _vm.isABussinesProfile)?_c('b-form-checkbox',{staticClass:"mt-1",model:{value:(_vm.generate_post),callback:function ($$v) {_vm.generate_post=$$v},expression:"generate_post"}},[_vm._v(" "+_vm._s(_vm.$t('Generate the post to the feed'))+" ")]):_vm._e()],1),(this.$route.params.id)?_c('div',{staticClass:"col-lg-3 pl-1 text-right nopadding"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.updateProduct}},[_vm._v(_vm._s(_vm.$t("Save changes")))])],1):_c('div',{staticClass:"col-lg-3 pl-1 text-right nopadding"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.createProduct}},[_vm._v(_vm._s(_vm.$t("Publish")))]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.closeCreationForm}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }