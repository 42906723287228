<template>
  <div>
    <validation-observer ref="productRules">
      <div class="w-100 px-1 py-2 mt-1 create-form-body row m-0">
        <div class="col-lg-9">
          <b-form-group
            :label="$t('Product title')"
            label-for="productTitle"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Product title')"
              rules="required|max:80"
            >
              <b-form-input
                id="productTitle"
                v-model="product_title"
                class="bg-light"
                :placeholder="$t('Place some text there...')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Description')"
            label-for="tinymce-editor"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
              rules="required"
            >
              <TinyMCE
                id="tinymce-editor"
                v-model="body"
                :placeholder="$t('Place some text there...')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group
              v-model="status"
              :options="statuses"
              class="mt-1"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Manufacturer')"
            label-for="manufacturer"
            class="mt-2"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Manufacturer')"
              rules="required"
            >
              <b-form-input
                id="manufacturer"
                v-model="manufacturer"
                class="bg-light"
                :placeholder="$t('Place some text there...')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Price')"
            label-for="price"
            class="mt-2"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Price')"
              rules="required"
            >
              <b-form-input
                id="price"
                v-model="price"
                class="bg-light"
                type="number"
                :placeholder="$t('Price')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-flex flex-wrap mb-1">
            <div
              class="
                file-input
                mt-1
                mr-1
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <img
                src="@/assets/images/pages/marketplace/file-drop-image.svg"
                alt=""
              >
              <h6 class="font-weight-bolder text-center px-1 pt-1">
                {{ $t("Drop an image here or") }}
                <span class="text-primary cursor-pointer">{{
                  $t("click")
                }}</span>
                {{ $t("to upload") }}
              </h6>
              <validation-provider
                #default="{ errors }"
                :name="$t('Images')"
                :rules="$route.params.id ? '' : 'required'"
              >
                <b-form-file
                  id="files"
                  v-model="files"
                  class="drop-input"
                  multiple
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div
              v-for="(link, index) in oldImages"
              :key="index"
              class="image-preview mr-1 mt-1"
            >
              <img
                :src="link.src ? link.src : link"
                alt=""
              >
              <img
                class="close-btn"
                src="@/assets/images/pages/marketplace/x-circle.svg"
                alt=""
                @click="deleteOldImage(index)"
              >
            </div>
            <div
              v-for="(link, index) in newImages"
              :key="`new-${index}`"
              class="image-preview mr-1 mt-1"
            >
              <img
                :src="link.src ? link.src : link"
                alt=""
              >
              <img
                class="close-btn"
                src="@/assets/images/pages/marketplace/x-circle.svg"
                alt=""
                @click="deleteImage(index)"
              >
            </div>
          </div>
          <b-form-checkbox
            v-if="!this.$route.params.id && isABussinesProfile"
            v-model="generate_post"
            class="mt-1"
          >
            {{ $t('Generate the post to the feed') }}
          </b-form-checkbox>
        </div>
        <div
          v-if="this.$route.params.id"
          class="col-lg-3 pl-1 text-right nopadding"
        >
          <b-button
            class="mr-2"
            variant="primary"
            @click="updateProduct"
          >{{
            $t("Save changes")
          }}</b-button>
        </div>
        <div
          v-else
          class="col-lg-3 pl-1 text-right nopadding"
        >
          <b-button
            class="mr-2"
            variant="primary"
            @click="createProduct"
          >{{
            $t("Publish")
          }}</b-button>
          <b-button
            variant="outline-primary"
            @click="closeCreationForm"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
// eslint-disable-next-line
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, max,
} from '@validations'
import {
  BButton,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TinyMCE from '@/components/editor/TinyMCE.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BFormFile,
    BFormRadioGroup,
    BFormCheckbox,
    TinyMCE,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    mode: {
      type: String,
      default: 'webcast',
    },
  },
  data() {
    return {
      product_title: null,
      body: null,
      required,
      max,
      links: [],
      files: [],
      status: 0,
      manufacturer: null,
      price: null,
      generate_post: false,
      statuses: [
        {
          value: 1,
          text: this.$t('Available now to order'),
        },
        {
          value: 0,
          text: this.$t('Currently not in stock'),
        },
      ],
    }
  },
  computed: {
    oldImages() {
      return this.links.filter(el => !el.new)
    },
    newImages() {
      return this.links.filter(el => el.new)
    },
    isABussinesProfile() {
      return this.$store.getters['auth/user'].active_business_profile.id
    },
  },
  watch: {
    files: {
      handler(val) {
        if (val.length > 5) {
          this.files = this.files.slice(0, 5)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('You can\'t upload more than 5 photos!'),
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            },
          })
        } else {
          this.links = []
          for (let i = 0; i < val.length; i += 1) {
            const reader = new FileReader()
            reader.readAsDataURL(val[i])
            reader.onloadend = () => {
              this.links.push({
                index: i,
                new: true,
                src: reader.result,
              })
            }
          }
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch('myProducts/fetchProductById', this.$route.params.id)
        .then(() => {
          const product = this.$store.getters['myProducts/selectedProduct']
          this.product_title = product.title
          this.body = product.body
          this.links = [product.main_image, ...product.images]
          this.manufacturer = product.manufacturer
          this.status = product.status
          this.price = product.price
        })
    }
  },
  methods: {
    deleteImage(index) {
      this.files.splice(index, 1)
      const links = this.links.filter(el => el.new)
      links.splice(index, 1)
      this.links = [...this.oldImages, ...links]
    },
    deleteOldImage(index) {
      this.$store.dispatch('myProducts/deleteProductImage', { id: this.$route.params.id, imageId: index }).then(response => {
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Your delete image successfully!'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.links.splice(index, 1)
          // this.$router.push(`/my_products/details/${this.$route.params.id}`)
        }
      })
    },
    createProduct() {
      this.$refs.productRules.validate().then(success => {
        if (success) {
          const form = new FormData()
          form.append('main_image', this.files[0])
          this.files.forEach((file, index) => { if (index > 0) form.append('images[]', file) })
          form.append('title', this.product_title)
          form.append('manufacturer', this.manufacturer)
          form.append('status', this.status)
          form.append('generate_post', this.generate_post)
          form.append('body', this.body)
          form.append('price', this.price)
          this.$store.dispatch('myProducts/createProduct', form).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Your product has been successfully added! After moderation, it will be published on our resource.'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.go(-1)
            }
          })
        }
      })
    },
    updateProduct() {
      this.$refs.productRules.validate().then(success => {
        if (success) {
          const form = new FormData()
          form.append('main_image', this.files[0])
          this.files.forEach((file, index) => { if (index > 0) form.append('images[]', file) })
          form.append('title', this.product_title)
          form.append('manufacturer', this.manufacturer)
          form.append('status', this.status)
          form.append('body', this.body)
          form.append('price', this.price)
          form.append('_method', 'PUT')
          this.$store.dispatch('myProducts/updateProduct', { product: form, id: this.$route.params.id }).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Your product has been successfully updated! After moderation, it will be published on our resource.'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.go(-1)
            }
          })
        }
      })
    },
    closeCreationForm() {
      this.$router.go(-1)
    },
  },
}
</script>
